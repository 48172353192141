import { publicUrl } from '../../helpers/public';
import { CaseStudyShape, SectionsTypes } from '../../types';

const PROJECT = '/assets/projects/03_kplus';

const K_PLUS: CaseStudyShape = {
  slug: 'k-plus',
  homepage: {
    image: publicUrl(`${PROJECT}/00_kplus.jpg`),
    caption: 'K+ Organics',
  },
  hero: {
    media: {
      alt: '',
      mobile: {
        image: publicUrl(`${PROJECT}/01_KPlus_Hero.jpg`),
      },
      desktop: {},
    },
    copy: '',
  },
  intro: {
    project: 'K+ Organics',
    role: 'Co-Founder / Design Director',
    copy:
      'As a founding member of K+ Organics, and the design director for this first-of-its kind organic sports drink, I was excited at the opportunity to help conceive of a new athlete-focused brand from concept to execution. The reaction to the K+ Organics product and brand was overwhelmingly positive with retail stores like Bristol Farms, Sprouts, and even Nike, ordering the drink for their shelves.',
  },
  sections: [
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/02_kplus_brand.gif`),
        alt: 'K+ Brand',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.parallaxImage,
      data: {
        image: publicUrl(`${PROJECT}/03_kplus hand.jpg`),
        alt: 'K+ hand holding bottle',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/04_kplus_pattern.jpg`),
        alt: 'K+ pattern',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/05_kplus_lifestyle.jpg`),
        alt: 'K+ lifestyle',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.parallaxImage,
      data: {
        image: publicUrl(`${PROJECT}/06_kplus_business card.jpg`),
        alt: 'K+ business card',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/07_kplus_tote.jpg`),
        alt: 'K+ pattern',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/08_kplus_soccer.jpg`),
        alt: 'K+ lifestyle',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.parallaxImage,
      data: {
        image: publicUrl(`${PROJECT}/09_kplus billboard.jpg`),
        mobileImage: publicUrl(`${PROJECT}/09_kplus billboard_mobile.jpg`),
        alt: 'K+ billboard',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/10_kplus wildposting.jpg`),
        alt: 'K+ wild posting',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/11_kplus_web.jpg`),
        alt: 'K+ website',
        objectFit: 'cover',
      },
    },
  ],
};

export default K_PLUS;
