export default function loadImage(url) {
  const img = new Image();

  return {
    element: img,
    promise: new Promise((resolve, reject) => {
      img.src = url;
      if (img.naturalWidth && img.naturalHeight && img.complete) {
        resolve(img);
      } else {
        img.onload = () => {
          resolve(img);
        };
        img.onerror = () => {
          reject(url);
        };
      }
      return img;
    }),
  };
}
