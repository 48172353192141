import Logo from '../Logo';
import cx from 'classnames';
import Wrapper from '../Wrapper';

import styles from './index.module.scss';

import CursorArea from '../Cursor/components/CursorArea';
import { IconTypes, Themes } from '../Cursor/types';
import { EMAIL } from '../../app.constants';

const Footer = ({ isVisible }) => (
  <div
    className={cx(styles.footer, 'fixed bottom-0 left-0 right-0 z-0', {
      [styles.isVisible]: isVisible,
    })}
  >
    <Wrapper
      tag="footer"
      className={cx('max-w-screen-2xl', 'py-24 md:py-32 xl:py-48')}
    >
      <div className="lg:flex lg:flex-row">
        <Logo className="md:mt-2" />
        <div className="lg:mr-auto lg:ml-auto w-full">
          <div className="lg:ml-24 xl:ml-32 2xl:ml-48">
            <p className={cx('text-xl font-semibold mb-24', styles.headline)}>
              Let's make something
              <br /> great together
            </p>
            <div className="lg:flex lg:flex-row">
              <p className="mb-8 lg:mr-auto copy">Matthew Cacciola</p>
              <nav className="copy">
                <CursorArea
                  theme={Themes.purple}
                  iconType={IconTypes.arrow}
                  className={cx(styles.link, 'mr-16')}
                >
                  <a href={`mailto:${EMAIL}?subject=Contact Matthew Cacciola`}>
                    Email
                  </a>
                </CursorArea>
                <CursorArea
                  theme={Themes.purple}
                  iconType={IconTypes.arrow}
                  className={cx(styles.link)}
                >
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.linkedin.com/in/matthew-cacciola-046a803/"
                  >
                    LinkedIn
                  </a>
                </CursorArea>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  </div>
);

export default Footer;
