import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

const LoaderDots = () => {
  return (
    <div className={cx(styles.root, 'absolute inset-0')}>
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
    </div>
  );
};

export default LoaderDots;
