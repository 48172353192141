import React, { forwardRef } from 'react';
import cx from 'classnames';
import Wrapper from '../Wrapper';

type FullWrapperProps = React.PropsWithChildren<{
  className?: string;
  tag?: keyof JSX.IntrinsicElements;
}>;

const FullWrapper = forwardRef<any, FullWrapperProps>(
  ({ children, className, tag }, forwardedRef) => {
    return (
      <Wrapper
        ref={forwardedRef}
        tag={tag}
        className={cx('my-9 md:my-12 lg:my-16 xl:my-32', className)}
      >
        {children}
      </Wrapper>
    );
  },
);

export default FullWrapper;
