import { Component } from 'react';
import PropTypes from 'prop-types';
import loadImage from './loadImage';

class ImageLoader extends Component {
  state = {
    hasLoaded: false,
    hasError: false,
  };

  static propTypes = {
    /** Array of images urls to load  */
    images: PropTypes.arrayOf(PropTypes.string.isRequired),
    /** Image url to load */
    image: PropTypes.string,
    onLoad: PropTypes.func,
  };

  componentDidMount() {
    this._mounted = true;

    if (this.props.image) {
      // save references to image so the src can be unset to cancel the image download
      this._image = loadImage(this.props.image);
      this._image.promise.then(this._handleLoad).catch(this._handleError);
    }

    if (this.props.images) {
      // save references to all images so the srcs can be unset to cancel the image download
      this._images = this.props.images.map((image) => loadImage(image));
      const promises = this._images.map(({ promise }) => promise);
      Promise.all(promises).then(this._handleLoad).catch(this._handleError);
    }
  }

  componentWillUnmount() {
    this._cancelImageDownload();
  }

  _cancelImageDownload() {
    // cancel image loading by setting src to empty
    // https://stackoverflow.com/questions/5278304/how-to-cancel-an-image-from-loading

    // flag to prevent setState callback on load
    this._mounted = false;

    if (this._image) {
      this._image.element.src = '';
    }

    if (this._images) {
      this._images.forEach((image) => {
        image.element.src = '';
      });
    }
  }

  _handleLoad = () => {
    if (!this._mounted) {
      return null;
    }
    this.setState({ hasLoaded: true });
    this.props.onLoad();
  };

  _handleError = (err) => {
    if (!this.mounted) {
      return null;
    }
    this.setState({ hasError: true, hasLoaded: false });
    console.warn(err);
  };

  render() {
    return this.props.children(this.state);
  }
}

export default ImageLoader;
