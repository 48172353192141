import { forwardRef } from 'react';
import cx from 'classnames';

import Arrow from './components/Arrow';
import Play from './components/Play';
import Close from './components/Close';

import styles from './index.module.scss';
import { IconTypes, Themes } from './types';

type CursorProps = {
  isActive: boolean;
  isVisible: boolean;
  iconType: keyof typeof IconTypes;
  theme: keyof typeof Themes;
};

const Cursor = forwardRef(
  ({ isActive, isVisible, iconType, theme }: CursorProps, ref: any) => {
    function renderIcon(iconType) {
      switch (iconType) {
        case IconTypes.arrow: {
          return <Arrow />;
        }
        case IconTypes['left-arrow']: {
          return <Arrow isLeft />;
        }
        case IconTypes.play: {
          return <Play />;
        }
        case IconTypes.close: {
          return <Close />;
        }
      }
    }

    return (
      <div
        ref={ref}
        className={cx(styles.cursor, {
          [styles.isActive]: isActive,
          [styles.isVisible]: isVisible,
          [styles.dark]: theme === Themes.dark,
          [styles.light]: theme === Themes.light,
          [styles.purple]: theme === Themes.purple,
        })}
      >
        <div className={styles.icon}>{isActive && renderIcon(iconType)}</div>
      </div>
    );
  },
);

export default Cursor;
