import { Themes } from './components/Cursor/types';

export enum SectionsTypes {
  'carousel' = 'carousel',
  'framedImage' = 'framedImage',
  'parallaxImage' = 'parallaxImage',
  'videoOnImage' = 'videoOnImage',
  'videoFull' = 'videoFull',
  'videoFramed' = 'videoFramed',
  'fairPremiumPhones' = 'fairPremiumPhones',
  'fairPhones' = 'fairPhones',
}

export type ObjectFit = 'contain' | 'cover';

type ImageShape = {
  ratio?: number;
  image: string;
  mobileImage?: string;
  mobileRatio?: number;
  alt?: string;
  className?: string;
};

type ResponsiveImage = {
  mobile: ImageShape;
  desktop: ImageShape;
};

type VideoShape = {
  theme?: keyof typeof Themes;
  ratio?: number;
  videoInline: string[];
  video?: string[];
  objectFit?: ObjectFit;
  poster?: string;
  className?: string;
};

export type CarouselData = {
  type: SectionsTypes.carousel;
  data: {
    slides: Array<ImageShape>; // | any
  };
};

export type FramedImageData = {
  type: SectionsTypes.framedImage;
  data: ImageShape & { objectFit?: ObjectFit };
};

export type ParallaxImageData = {
  type: SectionsTypes.parallaxImage;
  data: ImageShape;
};

export type VideoOnImageData = {
  type: SectionsTypes.videoOnImage;
  data: VideoShape & ResponsiveImage;
};

export type VideoFullData = {
  type: SectionsTypes.videoFull;
  data: VideoShape;
};

export type VideoFramedData = {
  type: SectionsTypes.videoFramed;
  data: VideoShape;
};

type Phone = {
  image?: string;
  video?: string;
};

export type FairPremiumPhonesData = {
  type: SectionsTypes.fairPremiumPhones;
  data: Phone[];
};

export type FairPhonesData = {
  type: SectionsTypes.fairPhones;
  data: Phone[];
};

// type GenericSection = {
//   type: keyof typeof SectionsTypes;
//   data: any;
// };

export type Sections =
  | CarouselData
  | FramedImageData
  | ParallaxImageData
  | VideoOnImageData
  | VideoFullData
  | VideoFramedData
  | FairPremiumPhonesData
  | FairPhonesData;

export type CaseStudyShape = {
  slug: string;
  protected?: boolean;
  password?: string;
  homepage: {
    theme?: keyof typeof Themes;
    image: string;
    caption: string;
  };
  intro: {
    copy: string;
    project: string;
    role: string;
  };
  hero: {
    media: {
      alt: string;
      mobile: any; //ImageShape | VideoShape | {};
      desktop: any; //ImageShape | VideoShape | {};
    };
    copy: string;
  };
  sections: Sections[];
};
