import { publicUrl } from '../../helpers/public';
import { CaseStudyShape, SectionsTypes } from '../../types';

const PROJECT = '/assets/projects/02_fair-premium';

const FAIR_PREMIUM: CaseStudyShape = {
  slug: 'fair-premium',
  homepage: {
    image: publicUrl(`${PROJECT}/00_fair_premium.jpg`),
    caption: 'Fair Premium',
  },
  hero: {
    media: {
      alt: '',
      mobile: {
        poster: publicUrl(
          `${PROJECT}/01_fair-premium_iphone-loop-1080-no-audio-poster.jpg`,
        ),
        videoInline: [
          publicUrl(`${PROJECT}/01_fair-premium_iphone-loop-1080-no-audio.mp4`),
        ],
      },
      desktop: {},
    },
    copy: '',
  },
  intro: {
    project: 'Fair Premium',
    role: 'Co-Founder / EVP of Design',
    copy:
      'Fair Premium took the Fair auto subscription model and pushed innovations and design forward toward an improved user experience and developed an entirely new product line focusing exclusively on the luxury vehicle segment and customer. Beyond just introducing luxury vehicle options, Fair Premium created a lifestyle brand and an exclusive, subscription-based community.',
  },
  sections: [
    {
      type: SectionsTypes.fairPremiumPhones,
      data: [
        {
          image: publicUrl(`${PROJECT}/02_fair-premium-phones/01_Home.png`),
        },
        {
          image: publicUrl(
            `${PROJECT}/02_fair-premium-phones/02_Experience.png`,
          ),
        },
        {
          image: publicUrl(`${PROJECT}/02_fair-premium-phones/03_Support.png`),
        },
        {
          image: publicUrl(`${PROJECT}/02_fair-premium-phones/04_Rewards.png`),
        },
        {
          image: publicUrl(`${PROJECT}/02_fair-premium-phones/05_Shop.png`),
        },
      ],
    },

    {
      type: SectionsTypes.framedImage,
      data: {
        ratio: 1.42,
        className: 'narrow',
        objectFit: 'contain',
        image: publicUrl(`${PROJECT}/03_fair-premium_poster.jpg`),
        alt: 'Fair premium poster',
      },
    },

    {
      type: SectionsTypes.videoFull,
      data: {
        // ratio: 0.703,
        videoInline: [
          publicUrl(`${PROJECT}/04_fair-premium-black-720-no-audio.mp4`),
        ],
      },
    },

    {
      type: SectionsTypes.videoFramed,
      data: {
        ratio: 1.42,
        className: 'narrow',
        objectFit: 'contain',
        videoInline: [
          publicUrl(`${PROJECT}/05_fair-premium_cards-720-no-audio.mp4`),
        ],
      },
    },

    {
      type: SectionsTypes.framedImage,
      data: {
        ratio: 0.703,
        image: publicUrl(`${PROJECT}/06_fair-premium_billboard.jpg`),
        alt: 'Fair premium billboard',
      },
    },
  ],
};

export default FAIR_PREMIUM;
