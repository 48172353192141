import { publicUrl } from '../../helpers/public';
import { CaseStudyShape, SectionsTypes } from '../../types';

const PROJECT = '/assets/projects/05_fair';

const FAIR: CaseStudyShape = {
  slug: 'fair',
  homepage: {
    image: publicUrl(`${PROJECT}/00_fair.jpg`),
    caption: 'Fair',
  },
  hero: {
    media: {
      alt: '',
      mobile: {
        image: publicUrl(`${PROJECT}/01_fair_mobile.jpg`),
      },
      desktop: {
        image: publicUrl(`${PROJECT}/01_fair_desktop.jpg`),
      },
    },
    copy: '',
  },
  intro: {
    project: 'Fair',
    role: 'Co-Founder / EVP of Design',
    copy:
      'Fair pioneered and created an entirely new vehicle subscriptions category, making it easy for consumers to subscribe to a vehicle right from their smartphone. The Fair app allows users to subscribe to a flexible, affordable, no term digital platform right at their fingertips. Fair’s success and growth was astonishing, acquiring nearly 100k subscribers, 4m app installs, a partnership with Uber, and a business valuation of $1.2 billion within the first several years of operation.',
  },
  sections: [
    {
      type: SectionsTypes.videoFramed,
      data: {
        // ratio: 0.703,
        poster: publicUrl(
          `${PROJECT}/02_fair_launch_video-720-no-audio-poster.jpg`,
        ),
        videoInline: [
          publicUrl(`${PROJECT}/02_fair_launch_video-720-no-audio.mp4`),
        ],
        video: [publicUrl(`${PROJECT}/02_fair_launch_video-1080.mp4`)],
      },
    },
    {
      type: SectionsTypes.fairPhones,
      data: [
        {
          image: publicUrl(`${PROJECT}/03_fair_phones/01_intro.png`),
        },
        {
          image: publicUrl(`${PROJECT}/03_fair_phones/02_home.png`),
        },
        {
          image: publicUrl(`${PROJECT}/03_fair_phones/03_listing.png`),
        },
        {
          image: publicUrl(`${PROJECT}/03_fair_phones/04_veh-detail.png`),
        },
        {
          image: publicUrl(`${PROJECT}/03_fair_phones/05_signature.png`),
        },
      ],
    },
    {
      type: SectionsTypes.videoFull,
      data: {
        ratio: 0.703,
        poster: publicUrl(
          `${PROJECT}/04_fair_scroll_video-1080-no-audio-poster.jpg`,
        ),
        videoInline: [publicUrl(`${PROJECT}/04_fair-scroll-video-updated.mp4`)],
      },
    },

    {
      type: SectionsTypes.framedImage,
      data: {
        ratio: 0.703,
        image: publicUrl(`${PROJECT}/05_fair_web.jpg`),
        alt: 'Fair Web',
      },
    },
    {
      type: SectionsTypes.videoFull,
      data: {
        // ratio: 0.703,
        video: [publicUrl(`${PROJECT}/06_fair_culture_video-updated-1080.mp4`)],
        videoInline: [
          publicUrl(
            `${PROJECT}/06_fair_culture_video_updated-720-no-audio.mp4`,
          ),
        ],
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        ratio: 0.703,
        image: publicUrl(`${PROJECT}/07_fair_billboard.jpg`),
        alt: 'Fair Billboard',
      },
    },
    {
      type: SectionsTypes.parallaxImage,
      data: {
        image: publicUrl(`${PROJECT}/08_fair_double.jpg`),
        alt: 'Fair Phones',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        ratio: 0.703,
        image: publicUrl(`${PROJECT}/09_fair_uber.jpg`),
        alt: 'Fair Uber',
      },
    },
  ],
};

export default FAIR;
