const Close = () => {
  return (
    <svg width="38px" height="38px" viewBox="0 0 38 38" version="1.1">
      <g
        id="Elements"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(1.000000, 1.000000)"
          stroke="currentColor"
          strokeWidth="2"
        >
          <line x1="0" y1="0" x2="36" y2="36" id="Line-2"></line>
          <line x1="0" y1="36" x2="36" y2="0" id="Line-2"></line>
        </g>
      </g>
    </svg>
  );
};

export default Close;
