import cx from 'classnames';
import React, { forwardRef } from 'react';

// type WrapperProps = React.PropsWithChildren<{
//   className?: string;
//   tag?: keyof JSX.IntrinsicElements;
// }>;

const Wrapper = forwardRef<any, any>(
  ({ children, className, tag: Tag }, forwardedRef) => {
    return (
      <Tag
        ref={forwardedRef}
        className={cx(
          'px-9 md:px-12 lg:px-16 xl:px-32 max-w-screen m-auto',
          className,
        )}
      >
        {children}
      </Tag>
    );
  },
);

Wrapper.defaultProps = {
  tag: 'div',
};

export default Wrapper;
