import cx from 'classnames';
import styles from './index.module.scss';

const RatioContainer = ({
  className,
  children,
  innerClass,
  ratio,
  style,
  ...rest
}: React.PropsWithChildren<{
  className?: string;
  innerClass?: string;
  /* height/width */
  ratio: number;
  style?: any;
}>) => {
  return (
    <div className={cx(className, styles.root)} style={style} {...rest}>
      <div style={{ paddingTop: ratio * 100 + '%' }}>
        <div className={cx(innerClass, styles.inner)}>{children}</div>
      </div>
    </div>
  );
};

export default RatioContainer;
