import { useState, useEffect, useCallback } from 'react';
// import throttle from 'lodash.throttle';
import { useEventListener } from '../../../hooks/useEventListener';
import { IconTypes, Themes } from '../types';

const activeScale = 1;
const defaultScale = 0.1;
const defaultIcon = IconTypes.arrow;
const defaultTheme = Themes.light;

const NO_CURSOR_CLASS = []; // 'no-cursor', 'cursor-none'

export type CursorCoreAPI = {
  isActive: boolean;
  isVisible: boolean;
  iconType: keyof typeof IconTypes;
  theme: keyof typeof Themes;
  setIconType: (iconType) => void;
  setIconDefault: () => void;
  setThemeDefault: () => void;
  setIsActive: (isActive: boolean) => void;
  setTheme: (theme) => void;
};

function useCursorCore({ cursorRef }): CursorCoreAPI {
  document.body.classList.add(...NO_CURSOR_CLASS);
  // probably wont need this
  //   const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [theme, setTheme] = useState(defaultTheme);
  const [iconType, setIconType] = useState(defaultIcon);
  const [isVisible, setIsVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActiveClickable, setIsActiveClickable] = useState(false);
  //   let endX = useRef(0);
  //   let endY = useRef(0);

  // Primary Mouse Move event

  const setCursorPosition = ({ clientX, clientY }) => {
    window.requestAnimationFrame(() => {
      // setCoords({ x: clientX, y: clientY });
      cursorRef.current.style.top = clientY + 'px';
      cursorRef.current.style.left = clientX + 'px';
      // endX.current = clientX;
      // endY.current = clientY;
    });
  };

  const onMouseMove = setCursorPosition;

  // Mouse Events State updates
  // const onMouseDown = useCallback(() => {
  //   setIsActive(true);
  // }, []);

  const onMouseUp = useCallback(() => {
    setIsActive(false);
  }, []);

  const onMouseEnterViewport = useCallback(() => {
    setIsVisible(true);
  }, []);

  const onMouseLeaveViewport = useCallback(() => {
    setIsVisible(false);
  }, []);

  useEventListener('mousemove', onMouseMove);
  // useEventListener('mousedown', onMouseDown);
  useEventListener('mouseup', onMouseUp);
  useEventListener('mouseover', onMouseEnterViewport);
  useEventListener('mouseout', onMouseLeaveViewport);

  // Cursors Hover/Active State
  useEffect(() => {
    if (isActive) {
      cursorRef.current.style.transform = `translate3d(-50%, -50%, 0) scale(${activeScale})`;
    } else {
      cursorRef.current.style.transform = `translate3d(-50%, -50%, 0) scale(${defaultScale})`;
    }
  }, [isActive, cursorRef]);

  // Cursors Click States
  useEffect(() => {
    if (isActiveClickable) {
      cursorRef.current.style.transform = `translate3d(-50%, -50%, 0) scale(${
        activeScale * 1.2
      })`;
    }
  }, [isActiveClickable, cursorRef]);

  // Target all possible clickables
  useEffect(() => {
    const clickables = document.querySelectorAll('a');

    const mouseOver = () => {
      setIsActive(true);
    };

    const click = () => {
      // setIsActive(true);
      // setIsActiveClickable(false);
    };

    const mouseDown = () => {
      // setIsActiveClickable(true);
    };

    const mouseUp = () => {
      // setIsActive(true);
    };

    const mouseOut = () => {
      setIsActive(false);
      // setIsActiveClickable(false);
    };

    clickables.forEach((el: HTMLElement) => {
      //   el.style.cursor = 'none';

      el.addEventListener('mouseover', mouseOver);
      el.addEventListener('click', click);
      el.addEventListener('mousedown', mouseDown);
      el.addEventListener('mouseup', mouseUp);
      el.addEventListener('mouseout', mouseOut);
    });

    return () => {
      clickables.forEach((el: HTMLElement) => {
        el.removeEventListener('mouseover', mouseOver);
        el.removeEventListener('click', click);
        el.removeEventListener('mousedown', mouseDown);
        el.removeEventListener('mouseup', mouseUp);
        el.removeEventListener('mouseout', mouseOut);
      });
    };
  }, [isActive]);

  const setIconDefault = () => setIconType(defaultIcon);
  const setThemeDefault = () => setTheme(defaultTheme);

  return {
    isActive,
    isVisible,
    iconType,
    theme,
    setIconType,
    setTheme,
    setIconDefault,
    setThemeDefault,
    setIsActive,
  };
}

export default useCursorCore;
