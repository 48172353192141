import React, { forwardRef } from 'react';

const Video = forwardRef<HTMLVideoElement, any>(
  ({ srcs, ...rest }, forwardedRef) => {
    return (
      <video
        // key={src} // Fix responsive updates: force react to update if source changes
        ref={forwardedRef}
        {...rest}
      >
        {srcs.map((src, i) => {
          const split = src.split('.');
          const ext = split[split.length - 1];
          const type = `video/${ext}`;
          return <source src={src} type={type} key={i} />;
        })}
      </video>
    );
  },
);

Video.defaultProps = {
  playsInline: true,
  muted: true,
  preload: 'auto',
};

export default Video;
