import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import loadImage from './components/ImageLoader/loadImage';
import { CASE_STUDIES } from './content/case-studies/index';

const preload = () => {
  CASE_STUDIES.map((caseStudy) => {
    const image = caseStudy.hero.media.mobile.image;
    return image && loadImage(image);
  });
};

window.onload = preload;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
