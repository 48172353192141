import { useCallback, useEffect } from 'react';

const isEventOutside = (ref, event) =>
  ref.current && !ref.current.contains(event.target);

export const useClickOutsideHandler = (ref, handler) => {
  const handleClick = useCallback(
    (event) => {
      // check a list of refs
      if (Array.isArray(ref)) {
        const allOutside = ref.every((r) => isEventOutside(r, event));

        if (allOutside) {
          handler();
        }
      } else if (isEventOutside(ref, event)) {
        handler();
      }
    },
    [ref, handler],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);
};
