import React, { useRef } from 'react';
import cx from 'classnames';
import CursorArea from '../../../Cursor/components/CursorArea';
import { IconTypes, Themes } from '../../../Cursor/types';
import Video from '../../../Video';
// import { isMobileDevice } from '../../../../helpers/isMobileDevice';

const VideoPlayerOverlay = ({ srcs, onClose, theme, poster }) => {
  const videoRef: any = useRef(null);

  // const isMobile = isMobileDevice();
  // useEffect(() => {
  //   if (videoRef.current && isMobile) {
  //     try {
  //       const videoEl: HTMLVideoElement = videoRef.current;
  //       // @ts-ignore
  //       videoEl.webkitEnterFullscreen();
  //       // @ts-ignore
  //       videoEl.enterFullscreen();
  //       videoEl.requestFullscreen().catch((err) => {
  //         alert(
  //           `Error attempting to enable full-screen mode: ${err.message} (${err.name})`,
  //         );
  //       });
  //     } catch (e) {
  //       console.warn(e);
  //     }
  //   }
  // }, [isMobile, videoRef]);

  return (
    <div
      className={cx(
        'fixed inset-0 z-50',
        theme === Themes.dark ? 'bg-black' : 'bg-white',
        {
          'text-white': theme === Themes.dark,
        },
      )}
    >
      <CursorArea
        theme={theme}
        className="absolute z-10 right-4 md:right-24 lg:right-32 top-1 md:top-10 lg:top-14"
        iconType={IconTypes.close}
      >
        <button className="font-medium px-4 py-2" onClick={onClose}>
          Close
        </button>
      </CursorArea>
      <div className="absolute inset-0 2xs:m-6 sm:m-9 md:m-24 lg:m-32">
        <Video
          className="absolute object-contain inset-0 w-full h-full"
          srcs={srcs}
          poster={poster}
          autoPlay
          loop={false}
          muted={false}
          onEnded={() => onClose()}
          ref={videoRef}
        />
      </div>
    </div>
  );
};

export default VideoPlayerOverlay;
