import { Themes } from '../../components/Cursor/types';
import { publicUrl } from '../../helpers/public';
import { CaseStudyShape, SectionsTypes } from '../../types';

const PROJECT = '/assets/projects/01_apple-music';

const APPLE_MUSIC: CaseStudyShape = {
  slug: 'apple-music',
  homepage: {
    theme: Themes.dark,
    image: publicUrl(`${PROJECT}/00_apple_music.jpg`),
    caption: 'Apple Music',
  },
  hero: {
    media: {
      alt: '',
      mobile: {
        image: publicUrl(`${PROJECT}/01_Hero.jpg`),
      },
      desktop: {},
    },
    copy: '',
  },
  intro: {
    project: 'Apple Music',
    role: 'Design Director',
    copy:
      "The launch of Apple Music was billed as “the most complete music experience on the planet.” For this ambitious project, I led a team of designers, collaborated with Apple’s in-house group, and worked with renowned typography designers and directors from around the world on an expansive global launch campaign that firmly reclaimed Apple's position as a leader in the world of music&nbsp;streaming.",
  },
  sections: [
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/02_fka-twigs.jpg`),
        alt: 'FKA Twigs',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.videoFull,
      data: {
        ratio: 0.703,
        poster: publicUrl(`${PROJECT}/03_apple-music-scroll-1080-poster`),
        videoInline: [publicUrl(`${PROJECT}/03_apple-music-scroll-1080.mp4`)],
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/04_music-gif_3.gif`),
        alt: 'Apple Music logos',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.videoOnImage,
      data: {
        mobile: {
          image: publicUrl(`${PROJECT}/05_flylo-background.jpg`),
        },
        desktop: {
          image: publicUrl(`${PROJECT}/05_flylo-background.jpg`),
        },
        poster: publicUrl(`${PROJECT}/05_flylo-video-poster.jpg`),
        videoInline: [
          publicUrl(`${PROJECT}/05_flylo-video-720-no-audio.mp4`),
          publicUrl(`${PROJECT}/05_flylo-video-720-no-audio.webm`),
        ],
        video: [publicUrl(`${PROJECT}/05_flylo-video-1080.mp4`)],
        theme: Themes.dark,
      },
    },
    {
      type: SectionsTypes.carousel,
      data: {
        slides: [
          {
            image: publicUrl(`${PROJECT}/06_artist/artist_01.jpg`),
            alt: 'Artist 1',
          },
          {
            image: publicUrl(`${PROJECT}/06_artist/artist_02.jpg`),
            alt: 'Artist 2',
          },
          {
            image: publicUrl(`${PROJECT}/06_artist/artist_03.jpg`),
            alt: 'Artist 3',
          },
          {
            image: publicUrl(`${PROJECT}/06_artist/artist_04.jpg`),
            alt: 'Artist 4',
          },
          {
            image: publicUrl(`${PROJECT}/06_artist/artist_05.jpg`),
            alt: 'Artist 5',
          },
          {
            image: publicUrl(`${PROJECT}/06_artist/artist_06.jpg`),
            alt: 'Artist 6',
          },
          {
            image: publicUrl(`${PROJECT}/06_artist/artist_07.jpg`),
            alt: 'Artist 7',
          },
          {
            image: publicUrl(`${PROJECT}/06_artist/artist_08.jpg`),
            alt: 'Artist 8',
          },
          {
            image: publicUrl(`${PROJECT}/06_artist/artist_09.jpg`),
            alt: 'Artist 9',
          },
          {
            image: publicUrl(`${PROJECT}/06_artist/artist_10.jpg`),
            alt: 'Artist 0',
          },
          {
            image: publicUrl(`${PROJECT}/06_artist/artist_11.jpg`),
            alt: 'Artist 1',
          },
        ],
      },
    },
    {
      type: SectionsTypes.videoOnImage,
      data: {
        mobile: {
          image: publicUrl(`${PROJECT}/07_james-bay-background-mobile.jpg`),
        },
        desktop: {
          image: publicUrl(`${PROJECT}/07_james-bay-background.jpg`),
        },
        videoInline: [
          publicUrl(`${PROJECT}/07_james-bay-video-720-no-audio.mp4`),
        ],
        video: [publicUrl(`${PROJECT}/07_james-bay-video-1080.mp4`)],
        poster: publicUrl(`${PROJECT}/07_james-bay-video-poster.jpg`),
        theme: Themes.dark,
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/08_artist-grid.jpg`),
        alt: 'Apple Music artists grid',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.videoFull,
      data: {
        theme: Themes.dark,
        videoInline: [publicUrl(`${PROJECT}/09_launch-spot-720-no-audio.mp4`)],
        video: [publicUrl(`${PROJECT}/09_launch-spot-1080.mp4`)],
        poster: publicUrl(`${PROJECT}/09_launch-spot-poster.jpg`),
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/10_billboard_james-bay.jpg`),
        alt: 'Billboard of James Bay',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.parallaxImage,
      data: {
        image: publicUrl(`${PROJECT}/11_apple-web.jpg`),
        alt: 'Apple Web',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/12_billboard_shamir.jpg`),
        alt: 'Billboard of Shamir',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.videoOnImage,
      data: {
        mobile: {
          image: publicUrl(`${PROJECT}/13_leon-background-mobile.jpg`),
        },
        desktop: {
          image: publicUrl(`${PROJECT}/13_leon-background.jpg`),
        },
        videoInline: [publicUrl(`${PROJECT}/13_leon-video-720-no-audio.mp4`)],
        video: [publicUrl(`${PROJECT}/13_leon-video-1080.mp4`)],
        poster: publicUrl(`${PROJECT}/13_leon-video-poster.jpg`),
        theme: Themes.dark,
      },
    },
  ],
};

export default APPLE_MUSIC;
