import cx from 'classnames';
import { useRef, useState } from 'react';
import { connect, getIn } from 'formik';
import { useClickOutsideHandler } from '../../hooks/useClickOutsideHandler';
import styles from './index.module.scss';

const Arrow = () => (
  <svg width="28px" height="22px" viewBox="0 0 28 22" version="1.1">
    <g
      transform="translate(-902.000000, -507.000000)"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g transform="translate(903.000000, 508.000000)">
        <line x1="0.285714286" y1="10" x2="25.4285714" y2="10" id="Line"></line>
        <line
          x1="15.7142857"
          y1="0.285714286"
          x2="25.4285714"
          y2="10"
          id="Line-Copy"
        ></line>
        <line
          x1="25.4285714"
          y1="10"
          x2="15.7142857"
          y2="19.7142857"
          id="Line-Copy-2"
        ></line>
      </g>
    </g>
  </svg>
);

type Props = {
  label: string;
  className?: string;
  type: string;
  name: string;
  placeholder: string;
  formik?: any;
  actionClickHandler?: () => void;
};

const ValidField = ({
  label,
  className,
  type,
  name,
  placeholder,
  formik,
  actionClickHandler,
  ...rest
}: Props) => {
  const [hasFocus, setHasFocus] = useState(false);

  const rootEl = useRef(null);
  useClickOutsideHandler(rootEl, () => setHasFocus(false));

  const value = getIn(formik.values, name);
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);
  const showError = touch && error;

  const hasInput = value.length > 0;

  return (
    <div className={cx(className, styles.root)} ref={rootEl}>
      <label
        className={cx(styles.field, {
          [styles.hasInput]: hasInput,
          [styles.hasFocus]: hasFocus,
          [styles.showError]: showError,
        })}
      >
        <input
          className={styles.input}
          onFocus={() => setHasFocus(true)}
          onChange={formik.handleChange}
          onBlur={(e) => {
            formik.handleBlur(e);
            setHasFocus(false);
          }}
          placeholder={label}
          type={type}
          name={name}
          value={value}
          {...rest}
        />

        <div className={styles.visualLabel}>{label}</div>

        {/* Action Helper Icon */}
        {hasInput && !error && (
          <button
            onClick={actionClickHandler}
            className={cx(styles.icon, {
              [styles.errorColor]: showError,
            })}
          >
            <Arrow />
          </button>
        )}
      </label>

      {showError && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default connect(ValidField);
