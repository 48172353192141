import { Redirect } from 'react-router-dom';
import { CASE_STUDIES_MAP } from '../../content/case-studies';
import { CaseStudyShape, SectionsTypes } from '../../types';

import Hero from './components/Hero';
import Carousel from './components/Carousel';
import FramedImage from './components/FramedImage';
import ParallaxImage from './components/ParallaxImage';
import VideoFull from './components/VideoFull';
import VideoOnImage from './components/VideoOnImage';

import VideoFramed from './components/VideoFramed/index';
import CaseStudyIntro from './components/CaseStudyIntro';
import FairPremiumPhones from './components/FairPremiumPhones';
import FairPhones from './components/FairPhones';
import Protected from './components/Protected';
import CaseStudyScroller from './components/CaseStudyScroller';

const ComponentMap = {
  [SectionsTypes.carousel]: Carousel,
  [SectionsTypes.framedImage]: FramedImage,
  [SectionsTypes.parallaxImage]: ParallaxImage,
  [SectionsTypes.videoOnImage]: VideoOnImage,
  [SectionsTypes.videoFull]: VideoFull,
  [SectionsTypes.videoFramed]: VideoFramed,
  [SectionsTypes.fairPremiumPhones]: FairPremiumPhones,
  [SectionsTypes.fairPhones]: FairPhones,
};

const CaseStudy = (props) => {
  const { slug } = props.match.params;
  const caseStudy: CaseStudyShape = CASE_STUDIES_MAP[slug];

  if (!caseStudy) {
    return <Redirect to="/" />;
  }

  return (
    <Protected caseStudy={caseStudy}>
      <CaseStudyScroller>
        <Hero hero={caseStudy.hero} />
        <div className="z-10 bg-white relative overflow-hidden">
          <CaseStudyIntro intro={caseStudy.intro} />
          <div className="bg-white min-h-screen overflow-hidden">
            {caseStudy.sections.map((section, i) => {
              const SectionModule = ComponentMap[section.type];
              if (SectionModule) {
                // @ts-ignore
                return <SectionModule data={section.data} key={i} />;
              }
              return null;
            })}
            <div data-spacer className="mt-9 md:mt-12 lg:mt-16 xl:mt-32"></div>
          </div>
        </div>
      </CaseStudyScroller>
    </Protected>
  );
};

export default CaseStudy;
