import { EMAIL } from '../../app.constants';
import { Copy } from '../Copy';
import CursorArea from '../Cursor/components/CursorArea';
import { IconTypes, Themes } from '../Cursor/types';
import Logo from '../Logo';
import Wrapper from '../Wrapper';

const Header = () => {
  return (
    <Wrapper
      tag="header"
      className="pt-20 md:pt-24 lg:pt-32 xl:pt-36 bg-white max-w-screen-2xl"
    >
      <div className="relative">
        <Logo />
        <Copy>
          <nav className="absolute right-0 top-2">
            <CursorArea
              theme={Themes.purple}
              iconType={IconTypes.arrow}
              className="text-purple"
            >
              <a href={`mailto:${EMAIL}?subject=Contact Matthew Cacciola`}>
                Contact
              </a>
            </CursorArea>
          </nav>
          <p>Designer</p>
          <p>Matthew Cacciola</p>
        </Copy>
        <hr className="w-2 mt-2 border-black" />
      </div>
    </Wrapper>
  );
};

export default Header;
