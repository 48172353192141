import React from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';
import { ROOT_MARGIN_IN_VIEW } from '../../../../app.constants';
import FullWrapper from '../../../FullWrapper';

import Image from '../../../Image';

import { FramedImageData } from '../../../../types';
import { useMedia } from '../../../../helpers/useMedia';
import { CASE_STUDY_SCROLLER_ID } from '../CaseStudyScroller';

type FramedImageProps = {
  data: FramedImageData['data'];
};

const FramedImage = ({ data }: FramedImageProps) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    root: document.getElementById(CASE_STUDY_SCROLLER_ID),
    rootMargin: ROOT_MARGIN_IN_VIEW,
  });

  const isMd = useMedia('(min-width: 768px)');

  const mobileRatio = data.mobileRatio || 0.86;

  const ratio = isMd
    ? data.ratio || 0.703
    : data.className === 'narrow'
    ? data.ratio
    : mobileRatio;

  return (
    <FullWrapper ref={ref}>
      <div className={cx('overflow-hidden', data.className)}>
        <Image
          inView={inView}
          src={data.image}
          alt={data.alt}
          ratio={ratio}
          className="relative"
          imageClass={`absolute object-${data.objectFit} inset-0 w-full h-full`}
        />
      </div>
    </FullWrapper>
  );
};

export default FramedImage;
