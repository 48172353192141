import { createContext, useRef } from 'react';

import Cursor from '..';
import useCursorCore from '../hooks/useCursorCore';

import { CursorCoreAPI } from '../hooks/useCursorCore';

export const CoreContext = createContext(null);

type CursorCoreContextProviderProps = {
  children: any;
};

const CursorCoreContextProvider = ({
  children,
}: CursorCoreContextProviderProps) => {
  const cursorRef: any = useRef();
  const cursorCore: CursorCoreAPI = useCursorCore({ cursorRef });

  return (
    <CoreContext.Provider value={cursorCore}>
      <Cursor
        ref={cursorRef}
        isVisible={cursorCore.isVisible}
        isActive={cursorCore.isActive}
        iconType={cursorCore.iconType}
        theme={cursorCore.theme}
      />
      {children}
    </CoreContext.Provider>
  );
};

export default CursorCoreContextProvider;
