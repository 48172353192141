import useCursorContext from '../hooks/useCursorContext';
import { PropsWithChildren } from 'react';
import { IconTypes, Themes } from '../types';

type CursorAreaProps = PropsWithChildren<{
  iconType: keyof typeof IconTypes;
  theme: keyof typeof Themes;
  className?: string;
  disabled?: boolean;
}>;

const CursorArea = ({
  children,
  iconType,
  className,
  disabled,
  theme,
}: CursorAreaProps) => {
  const cursorContext = useCursorContext();

  const setActive = () => {
    cursorContext?.setIconType(iconType);
    cursorContext?.setIsActive(true);
    cursorContext?.setTheme(theme);
  };

  const setDefault = () => {
    cursorContext?.setIconDefault();
    cursorContext?.setIsActive(false);
    cursorContext?.setThemeDefault();
  };

  const listeners =
    disabled || !cursorContext
      ? {}
      : {
          onMouseEnter: setActive,
          onMouseMove: () => {
            // TODO: throttle this
            if (!cursorContext.isActive) {
              setActive();
            }
          },
          onMouseLeave: setDefault,
        };

  return (
    <div className={className} {...listeners}>
      {children}
    </div>
  );
};

CursorArea.defaultProps = {
  theme: Themes.light,
};

export default CursorArea;
