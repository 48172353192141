import { useEffect, useState } from 'react';

export function useScroller({
  ref,
  tolerance,
  onScrollComplete,
}: {
  ref: any;
  tolerance: number;
  onScrollComplete: any;
}) {
  const [hasScrollCompleted, setHasScrollCompleted] = useState(false);

  useEffect(() => {
    const onScroll = (event) => {
      const el = event.target;
      const trigger =
        el.scrollTop + tolerance >= el.scrollHeight - el.offsetHeight;

      if (trigger && !hasScrollCompleted) {
        setHasScrollCompleted(true);
        onScrollComplete();
      }
      return null;
    };

    const el = ref.current;
    if (ref.current) {
      el.addEventListener('scroll', onScroll);
    }
    return () => el?.removeEventListener('scroll', onScroll);
  }, [ref, onScrollComplete, hasScrollCompleted, tolerance]);
}
