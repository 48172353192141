import { isMobileDevice } from '../../../helpers/isMobileDevice';
import CursorCoreContextProvider from '../context/CursorCoreContextProvider';

type CursorCoreProps = {
  children: any;
};

const CursorCore = ({ children }: CursorCoreProps) => {
  const isMobile = isMobileDevice();

  if (!isMobile) {
    return <CursorCoreContextProvider>{children}</CursorCoreContextProvider>;
  }

  return children;
};

export default CursorCore;
