import VideoWithPlayer from '../VideoWithPlayer';

import { VideoOnImageData } from '../../../../types';
import { useMedia } from '../../../../helpers/useMedia';

type VideoOnImageProps = {
  data: VideoOnImageData['data'];
};

const VideoOnImage = ({ data }: VideoOnImageProps) => {
  const isMd = useMedia('(min-width: 768px)');
  const src = isMd ? data.desktop.image : data.mobile.image;
  return (
    <div
      style={{
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
      }}
    >
      <div className="px-9 py-16 md:px-32 md:py-16 lg:px-48 lg:py-24 xl:px-64 xl:py-32 2xl:px-96 2xl:py-48">
        <VideoWithPlayer data={data} />
      </div>
    </div>
  );
};

export default VideoOnImage;
