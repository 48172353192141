import APPLE_MUSIC from './01-apple-music';
import FAIR_PREMIUM from './02-fair-premium';
import K_PLUS from './03-kplus';
import APPLE_COLLECTION from './04-apple';
import FAIR from './05-fair';
import MEDIA_ARTS_LAB from './06-media-arts-lab';

import { CaseStudyShape } from '../../types';

export const CASE_STUDIES: CaseStudyShape[] = [
  APPLE_MUSIC,
  FAIR_PREMIUM,
  K_PLUS,
  APPLE_COLLECTION,
  FAIR,
  MEDIA_ARTS_LAB,
];

export const CASE_STUDIES_MAP = CASE_STUDIES.reduce((a, c) => {
  a[c.slug] = c;
  return a;
}, {});
