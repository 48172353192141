import cx from 'classnames';
import styles from './index.module.scss';

const Logo = ({
  isWhite,
  className,
}: {
  isWhite?: boolean;
  className?: string;
}) => {
  return (
    <div className={cx('mb-12 xl:mb-32', styles.logo, className)}>
      <svg viewBox="0 0 48 35" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>M Logotype</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-37.000000, -78.000000)"
            fill={isWhite ? '#ffffff' : '#000000'}
          >
            <g transform="translate(37.000000, 78.000000)">
              <g transform="translate(0.000490, 0.398860)">
                <path d="M4.5353,0.93065 L1.10622622e-12,0.93065 C0.3353,2.40205 0.5768,3.79785 0.7245,5.12015 C0.8708,6.44315 0.9443,7.69335 0.9443,8.86865 L0.9443,33.50165 L6.993,33.50165 L6.993,7.86065 C6.993,6.60135 6.8348,5.41415 6.5205,4.30115 C6.2055,3.18955 5.5433,2.06465 4.5353,0.93065"></path>
                <path d="M46.74586,5.59279 C46.24116,4.29149 45.55866,3.24149 44.69766,2.44279 C43.83666,1.64549 42.84966,1.08829 41.73736,0.77329 C40.62366,0.45829 39.43786,0.30079 38.17786,0.30079 C37.04386,0.30079 35.68936,0.52129 34.11436,0.96229 C32.79206,1.33259 31.22266,2.02559 29.41106,3.03289 L31.25766,6.19269 L33.91556,5.47939 C34.15776,5.44229 34.40066,5.40099 34.64986,5.34079 C35.23786,5.21479 35.74116,5.09999 36.16186,4.99499 C36.58116,4.88999 37.04386,4.83679 37.54716,4.83679 C38.89116,4.83679 39.87816,5.29949 40.50886,6.22279 C41.13816,7.14749 41.45316,8.34449 41.45316,9.81379 L41.45316,33.50179 L47.50186,33.50179 L47.50186,10.44379 C47.50186,8.51249 47.24986,6.89479 46.74586,5.59279"></path>
                <path d="M24.60087,2.44279 C23.78187,1.64549 22.82637,1.08829 21.73437,0.77329 C20.64237,0.45829 19.48737,0.30079 18.26937,0.30079 C17.13537,0.30079 15.81237,0.52129 14.30037,0.96229 C13.03057,1.33259 11.50457,2.01159 9.73007,2.98949 L11.60187,6.19269 L15.80817,5.06359 C15.96287,5.02859 16.12037,4.99289 16.25337,4.96349 C16.63137,4.87879 17.07237,4.83679 17.57707,4.83679 C18.79437,4.83679 19.70787,5.29949 20.31757,6.22279 C20.92517,7.14749 21.23107,8.34449 21.23107,9.81379 L21.23107,33.50179 L27.27837,33.50179 L27.27837,10.44379 C27.27837,8.51249 27.03617,6.89479 26.55387,5.59279 C26.07087,4.29149 25.42057,3.24149 24.60087,2.44279"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Logo;
