import { Themes } from '../../components/Cursor/types';
import { publicUrl } from '../../helpers/public';
import { CaseStudyShape, SectionsTypes } from '../../types';

const PROJECT = '/assets/projects/06_media-arts-lab';

const MEDIA_ARTS_LAB: CaseStudyShape = {
  slug: 'media-arts-lab',
  homepage: {
    theme: Themes.dark,
    image: publicUrl(`${PROJECT}/00_media-arts-lab.jpg`),
    caption: 'Media Arts Lab',
  },
  hero: {
    media: {
      alt: '',
      mobile: {
        image: publicUrl(`${PROJECT}/01_mal_flag_mobile.jpg`),
      },
      desktop: {
        image: publicUrl(`${PROJECT}/01_mal_flag_desktop.jpg`),
      },
    },
    copy: '',
  },
  intro: {
    project: 'Media Arts Lab',
    role: 'Design Director',
    copy:
      "In 2006, TBWA / Media Arts Lab was established as Apple’s in-house creative agency, founded and headed by advertising icon Lee Clow. After a decade producing some of the world's most iconic Apple advertising and print campaigns, Media Arts Lab was ready for a brand refresh. I worked alongside Lee and the talented Media Arts design team to completely rethink and update every aspect of the&nbsp;brand.",
  },
  sections: [
    {
      type: SectionsTypes.framedImage,
      data: {
        ratio: 0.703,
        image: publicUrl(`${PROJECT}/02_mal_gif.gif`),
        mobileRatio: 0.703,
        alt: 'MAL',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.parallaxImage,
      data: {
        image: publicUrl(`${PROJECT}/03_mal_news.jpg`),
        alt: 'MAL news',
      },
    },
    {
      type: SectionsTypes.videoFramed,
      data: {
        ratio: 0.703,
        poster: publicUrl(`${PROJECT}/04_mal_web-1080-no-audio-poster.jpg`),
        videoInline: [publicUrl(`${PROJECT}/04_mal_web-1080-no-audio.mp4`)],
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/05_mal_tote.jpg`),
        ratio: 0.703,
        alt: 'MAL tote',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.parallaxImage,
      data: {
        image: publicUrl(`${PROJECT}/06_mal_business cards.jpg`),
        alt: 'MAL business cards',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/07_mal_set.jpg`),
        ratio: 0.703,
        alt: 'MAL set',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/08_mal_envelope.jpg`),
        ratio: 0.703,
        alt: 'MAL envelope',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.parallaxImage,
      data: {
        image: publicUrl(`${PROJECT}/09_mal_booklet.jpg`),
        alt: 'MAL booklet',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/10_mal_tape.jpg`),
        ratio: 0.703,
        alt: 'MAL tape',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/11_mal_notebook.jpg`),
        ratio: 0.703,
        alt: 'MAL notebook',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.parallaxImage,
      data: {
        image: publicUrl(`${PROJECT}/12_mal_poster.gif`),
        alt: 'MAL posters',
      },
    },
  ],
};

export default MEDIA_ARTS_LAB;
