import React from 'react';
import FullWrapper from '../../../FullWrapper';
import VideoWithPlayer from '../VideoWithPlayer';

import { VideoFramedData } from '../../../../types';

type VideoFramedProps = {
  data: VideoFramedData['data'];
};

const VideoFramed = (props: VideoFramedProps) => {
  return (
    <FullWrapper>
      <VideoWithPlayer {...props} />
    </FullWrapper>
  );
};

export default VideoFramed;
