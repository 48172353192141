import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import TouchCarousel from 'react-touch-carousel';

import FullWrapper from '../../../FullWrapper';
import RatioContainer from '../../../RatioContainer';
import { ROOT_MARGIN_IN_VIEW } from '../../../../app.constants';
import CursorArea from '../../../Cursor/components/CursorArea';
import { IconTypes } from '../../../Cursor/types';
import { CarouselData } from '../../../../types';
import styles from './index.module.scss';

import Image from '../../../Image';
import NonPassiveTouchTarget from './NonPassiveTouchTarget';
import touchWithMouseHOC from './touchWithMouseHOC';
import { isMobileDevice } from '../../../../helpers/isMobileDevice';
import { CASE_STUDY_SCROLLER_ID } from '../CaseStudyScroller';

// https://github.com/xiaody/react-touch-carousel

type CarouselProps = {
  data: CarouselData['data'];
};

const CarouselContainer = (slides) =>
  touchWithMouseHOC((props) => {
    const {
      cursor,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      carouselState: { active, dragging },
      ...rest
    } = props;

    let current = -Math.round(cursor) % slides.length;
    while (current < 0) {
      current += slides.length;
    }
    return (
      <>
        <RatioContainer ratio={1.42} className={styles.slide}>
          <NonPassiveTouchTarget {...rest} />
        </RatioContainer>
        <div className={styles.pages}>
          <p className="text-xs font-medium md:text-base">
            {current + 1} / {slides.length}
          </p>
        </div>
      </>
    );
  });

const Carousel = ({ data }: CarouselProps) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    root: document.getElementById(CASE_STUDY_SCROLLER_ID),
    rootMargin: ROOT_MARGIN_IN_VIEW,
  });
  // start/stop auto play on blur
  const handleBlur = () => {
    carousel?.current?.stopAutoplay();
  };
  const handleFocus = () => {
    carousel?.current?.autoplayIfEnabled();
  };

  useEffect(() => {
    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
    };
  });

  const carousel = useRef(null);

  const handleNext = () => {
    carousel?.current.stopAutoplay();
    carousel?.current.next();
  };
  const handlePrev = () => {
    carousel?.current.prev();
  };

  const isMobile = isMobileDevice();
  const cardSize = window.innerWidth / 4;
  const cardSizeMobile = window.innerWidth * 0.8;
  const cardPadCount = 2;

  return (
    <FullWrapper ref={ref}>
      {inView && (
        <div
          className="relative"
          onDragStart={(e) => {
            // prevent image dragging
            e.preventDefault();
          }}
        >
          {/* @ts-ignore */}
          <React.StrictMode>
            <TouchCarousel
              ref={carousel}
              component={CarouselContainer(data.slides)}
              cardSize={isMobile ? cardSizeMobile : cardSize}
              // @ts-ignore
              cardCount={data.slides.length}
              cardPadCount={cardPadCount}
              autoplay={5000}
              renderCard={(index, modIndex, cursor) => {
                cursor = -cursor;

                // @ts-ignore
                const { image, alt } = data.slides[modIndex];

                const translateCard =
                  index > cursor
                    ? 100 * (index - cursor)
                    : 50 * (index - cursor);
                // const translateText = index < cursor ? 5 * (index - cursor) : 0;
                // const translateTitle = translateText * 1.2;
                // const scaleTitle = index < cursor ? 1 - 0.1 * (cursor - index) : 1;
                const scaleTitle = 1;
                return (
                  <div
                    key={index}
                    className="absolute object-contain inset-0 w-50 h-full"
                    style={{
                      transform: `translate3d(${translateCard}%, 0, 0) scale(${scaleTitle})`,
                    }}
                  >
                    <Image
                      src={image}
                      alt={alt}
                      ratio={1.42}
                      className="relative"
                      imageClass="absolute object-contain inset-0 w-50 h-full"
                    />
                  </div>
                );
              }}
            />
          </React.StrictMode>
          {!isMobile && (
            <>
              <CursorArea iconType={IconTypes['left-arrow']}>
                <button className={styles.prevEl} onClick={handlePrev} />
              </CursorArea>
              <CursorArea iconType={IconTypes.arrow}>
                <button className={styles.nextEl} onClick={handleNext} />
              </CursorArea>
            </>
          )}
        </div>
      )}
    </FullWrapper>
  );
};

export default Carousel;
