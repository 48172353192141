export const addToSessionStorage = (key, value) =>
  window.sessionStorage.setItem(key, JSON.stringify(value));

export const removeFromSessionStorage = (key) =>
  window.sessionStorage.removeItem(key);

export const getFromSessionStorage = (key) => {
  const item = window.sessionStorage.getItem(key);

  if (item !== null) {
    return JSON.parse(item);
  }

  return null;
};
