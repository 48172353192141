import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import scaleBetween from '../../../../helpers/scaleBetween';
import cx from 'classnames';
import styles from './index.module.scss';
import { CASE_STUDY_HERO } from '../Hero';

const BlurReveal = ({ scrollContainer }) => {
  const el: any = useRef(null);

  useEffect(() => {
    const height = window.innerHeight;

    const onScroll = () => {
      const blurEl = el.current;
      if (!blurEl) return;

      const rect = blurEl.getBoundingClientRect();
      if (rect.top > height) return;

      const x = scaleBetween(rect.top, 0, 1, 0, height);

      window.requestAnimationFrame(() => {
        blurEl.style.setProperty('--blur-reveal', `${x * 40}px`);
        blurEl.style.setProperty('--blur-brightness', ` ${50 + (1 - x) * 50}%`);
      });
    };

    scrollContainer.addEventListener('scroll', onScroll);
    return () => {
      scrollContainer.removeEventListener('scroll', onScroll);
    };
  });

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
    rootMargin: '100px 100px 100px 100px',
  });

  useEffect(() => {
    // this remove the opacity that hides the home page while the case study is active
    // this prevent glitching during scrolling from the underlying homepage graphics
    // displaying through on the case study
    const homepage = document.querySelector('.homepage');
    const hero = document.getElementById(CASE_STUDY_HERO);
    if (!inView) {
      homepage.classList.remove(styles.revealHome);
      hero.classList.remove('invisible');
    }
    // remove opacity
    if (inView) {
      homepage.classList.add(styles.revealHome);
      hero.classList.add('invisible');
    }

    // on unmount
    return () => {
      homepage.classList.remove(styles.revealHome);
    };
  }, [inView]);

  return (
    <div ref={ref}>
      <div ref={el} className={cx('h-screen', styles.reveal)} />
    </div>
  );
};

export default BlurReveal;
