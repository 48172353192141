import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

import Homepage from './components/Homepage';
import CaseStudyController from './components/CaseStudyController';
import CursorCore from './components/Cursor/components/CursorCore';

function App() {
  return (
    <ParallaxProvider>
      <CursorCore>
        <Router>
          <main>
            <Route path={['/', '/case-study/*']} exact component={Homepage} />
            <Switch>
              <Route
                path="/case-study/:slug"
                exact
                component={CaseStudyController}
              />
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </main>
        </Router>
      </CursorCore>
    </ParallaxProvider>
  );
}

export default App;
