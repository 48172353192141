import { Themes } from '../../components/Cursor/types';
import { publicUrl } from '../../helpers/public';
import { CaseStudyShape, SectionsTypes } from '../../types';

const PROJECT = '/assets/projects/04_apple';

const APPLE_COLLECTION: CaseStudyShape = {
  slug: 'apple-collection',
  protected: true,
  password: 'Apple_8',
  homepage: {
    image: publicUrl(`${PROJECT}/00_apple.jpg`),
    caption: 'Apple Collection',
  },
  hero: {
    media: {
      alt: '',
      mobile: {
        image: publicUrl(`${PROJECT}/01_apple-still.jpg`),
        // video: [publicUrl(`${PROJECT}/01_apple-video.mp4`)],
      },
      desktop: {},
    },
    copy: '',
  },
  intro: {
    project: 'Apple',
    role: 'Design Director',
    copy:
      "When Apple launches a new product, the whole world stops to pay attention. Working exclusively for Apple for nearly eight years, I had the rare opportunity to become part of a unique family-type atmosphere working with some of the world's best thinkers. While there I collaborated on a number of high profile Apple launches, including global print and broadcast launch campaigns for products such as iPod, iPhone, iPad, and most recently Apple Music.",
  },
  sections: [
    {
      type: SectionsTypes.parallaxImage,
      data: {
        image: publicUrl(`${PROJECT}/02_apple_iphone-6_mix.jpg`),
        alt: 'Apple iPhone 6 mix',
      },
    },
    {
      type: SectionsTypes.carousel,
      data: {
        slides: [
          {
            image: publicUrl(
              `${PROJECT}/03_apple-iphone-6/03_apple_iphone-6_p1.jpg`,
            ),
            alt: 'Apple iPhone 6 image 1',
          },
          {
            image: publicUrl(
              `${PROJECT}/03_apple-iphone-6/03_apple_iphone-6_p2.jpg`,
            ),
            alt: 'Apple iPhone 6 image 2',
          },
          {
            image: publicUrl(
              `${PROJECT}/03_apple-iphone-6/03_apple_iphone-6_p3.jpg`,
            ),
            alt: 'Apple iPhone 6 image 3',
          },
        ],
      },
    },
    {
      type: SectionsTypes.parallaxImage,
      data: {
        image: publicUrl(`${PROJECT}/04_apple_ipod_scroll.jpg`),
        alt: 'Apple iPod scroll',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        ratio: 1.42,
        image: publicUrl(`${PROJECT}/05_apple_ipod-print.jpg`),
        alt: 'Apple iPod print',
        className: 'narrow',
        objectFit: 'contain',
      },
    },
    {
      type: SectionsTypes.videoFull,
      data: {
        poster: publicUrl(`${PROJECT}/06_iphone-5c-720-no-audio-poster.jpg`),
        videoInline: [publicUrl(`${PROJECT}/06_iphone-5c-720-no-audio.mp4`)],
        video: [publicUrl(`${PROJECT}/06_iphone-5c-1080.mp4`)],
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/07_iphone5c_billboard.jpg`),
        alt: 'Apple iPhone 5c billboard',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.parallaxImage,
      data: {
        image: publicUrl(`${PROJECT}/08_apple_store_scroll.jpg`),
        alt: 'Apple store',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/09_apple_iphone-5s_p1.jpg`),
        alt: 'Apple iPhone 5s',
        objectFit: 'contain',
        ratio: 1.42,
        className: 'narrow',
      },
    },
    {
      type: SectionsTypes.videoFull,
      data: {
        theme: Themes.dark,
        videoInline: [publicUrl(`${PROJECT}/10_iphone-5s-720-no-audio.mp4`)],
        video: [publicUrl(`${PROJECT}/10_iphone-5s-1080.mp4`)],
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        ratio: 0.703,
        image: publicUrl(`${PROJECT}/11_apple-ipad.jpg`),
        alt: 'Apple iPad',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.videoFull,
      data: {
        poster: publicUrl(`${PROJECT}/12_ipad-air-2-720-no-audio-poster.mp4`),
        videoInline: [publicUrl(`${PROJECT}/12_ipad-air-2-720-no-audio.mp4`)],
        video: [publicUrl(`${PROJECT}/12_ipad-air-2-1080.mp4`)],
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/13_apple-tv.jpg`),
        alt: 'Apple TV',
        objectFit: 'cover',
      },
    },
    {
      type: SectionsTypes.carousel,
      data: {
        slides: [
          {
            image: publicUrl(`${PROJECT}/14_nano/14_nano_p1.jpg`),
            alt: 'Apple Nano image 1',
          },
          {
            image: publicUrl(`${PROJECT}/14_nano/14_nano_p2.jpg`),
            alt: 'Apple Nano image 2',
          },
          {
            image: publicUrl(`${PROJECT}/14_nano/14_nano_p3.jpg`),
            alt: 'Apple Nano image 3',
          },
        ],
      },
    },
    {
      type: SectionsTypes.parallaxImage,
      data: {
        image: publicUrl(`${PROJECT}/15_nano_scroll.jpg`),
        alt: 'Apple Nano albums',
      },
    },
    {
      type: SectionsTypes.framedImage,
      data: {
        image: publicUrl(`${PROJECT}/16_ipod-touch.jpg`),
        alt: 'Apple TV',
        objectFit: 'contain',
        ratio: 1.42,
        className: 'narrow',
      },
    },
  ],
};

export default APPLE_COLLECTION;
