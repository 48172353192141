import React, { useEffect, useState } from 'react';

import Header from '../Header';
import Intro from '../Intro';
import Footer from '../Footer';

import CaseStudyCard from '../CaseStudyCard';
import Wrapper from '../Wrapper';

import cx from 'classnames';
import styles from './index.module.scss';

import { CASE_STUDIES } from '../../content/case-studies';

import { useEventListener } from '../../hooks/useEventListener';

const SCROLL_LOCK = ['overflow-hidden'];
// const SCROLL_LOCKED_CLASS = ['fixed', 'inset-0'];

const Homepage = ({ location }) => {
  // observe scrolling

  const [isVisible, setFooterVisible] = useState(false);

  const onScroll = () => {
    const trigger = window.pageYOffset >= window.innerHeight;

    if (isVisible !== trigger) {
      setFooterVisible(trigger);
    }
  };

  useEventListener('scroll', onScroll);

  const isHome = location.pathname === '/';

  useEffect(() => {
    // const scrollY = window.pageYOffset;
    if (!isHome) {
      document.body.classList.add(...SCROLL_LOCK);
      // document.body.style.top = `-${scrollY}px`;
    } else {
      // const top = document.body.style.top;
      document.body.classList.remove(...SCROLL_LOCK);
      // document.body.style.top = '';
      // const y = parseInt(top || '0') * -1;
      // console.log(y);
      // window.scrollTo(0, y);
    }
  }, [isHome]);

  return (
    <section
      className={cx('homepage', styles.page, {
        [styles.hidden]: !isHome,
      })}
    >
      <div
        className={cx(
          'relative min-h-screen bg-white z-10 pb-24 md:pb-32 xl:pb-48',
        )}
      >
        <Header />
        <Intro />
        <Wrapper
          tag="section"
          className="max-w-screen-2xl mt-12 flex flex-col flex-nowrap"
        >
          {CASE_STUDIES.map(
            ({ homepage: { image, caption, theme }, slug }, i) => (
              <CaseStudyCard
                image={image}
                slug={slug}
                caption={caption}
                key={slug}
                theme={theme}
                className={
                  i % 2 === 0
                    ? 'lg:pr-9 xl:pr-16 self-start'
                    : 'lg:pl-9 xl:pl-16 self-end'
                }
              />
            ),
          )}
        </Wrapper>
      </div>
      <Footer isVisible={isVisible} />
    </section>
  );
};

export default Homepage;
