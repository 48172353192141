import React from 'react';
import cx from 'classnames';

import ImageLoader from '../ImageLoader';
import RatioContainer from '../RatioContainer';
import styles from './index.module.scss';
import LoaderDots from '../LoaderDots';

type ImageProps = {
  className?: string;
  src: string;
  ratio: number;
  alt?: string;
  onLoad?: () => unknown;
  inView?: boolean;
  imageClass?: string;
};

const Image = ({
  src,
  className,
  ratio,
  alt,
  onLoad,
  inView,
  imageClass,
  ...rest
}: ImageProps) => {
  return inView ? (
    <ImageLoader image={src} onLoad={onLoad}>
      {({ hasLoaded }) => (
        <RatioContainer
          className={className}
          innerClass={styles.container}
          ratio={ratio}
        >
          <img
            className={cx(
              'absolute inset-0 w-full h-full object-cover',

              styles.image,
              imageClass,
              {
                [styles.hasLoaded]: hasLoaded,
              },
            )}
            draggable="false"
            src={src}
            alt={alt}
            {...rest}
          />

          {!hasLoaded && <LoaderDots />}
        </RatioContainer>
      )}
    </ImageLoader>
  ) : (
    <RatioContainer
      className={className}
      innerClass={styles.container}
      ratio={ratio}
    />
  );
};

Image.defaultProps = {
  inView: true,
};

export default Image;
