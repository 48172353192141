import styles from './Arrow.module.scss';

const Arrow = ({ isLeft }: { isLeft?: boolean }) => (
  <svg
    className={isLeft && styles.flip}
    width="47px"
    height="37px"
    viewBox="0 0 47 37"
    version="1.1"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g transform="translate(1.000000, 1.000000)">
        <line x1="0.5" y1="17.5" x2="44.5" y2="17.5" id="Line"></line>
        <line x1="27.5" y1="0.5" x2="44.5" y2="17.5" id="Line-Copy"></line>
        <line x1="44.5" y1="17.5" x2="27.5" y2="34.5" id="Line-Copy-2"></line>
      </g>
    </g>
  </svg>
);

export default Arrow;
