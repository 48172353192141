import VideoWithPlayer from '../VideoWithPlayer';

import { VideoFullData } from '../../../../types';

type VideoFullProps = {
  data: VideoFullData['data'];
};

const VideoFull = ({ data }: VideoFullProps) => {
  return <VideoWithPlayer data={data} />;
};

export default VideoFull;
