import { PropsWithChildren, useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import CursorArea from '../../../Cursor/components/CursorArea';
import { IconTypes, Themes } from '../../../Cursor/types';
import ValidField from '../../../ValidField';
import Logo from '../../../Logo';

import { Form, Formik } from 'formik';
import {
  addToSessionStorage,
  getFromSessionStorage,
} from '../../../../helpers/sessionStorage';

import { CaseStudyShape } from '../../../../types';
import { EMAIL } from '../../../../app.constants';

import styles from './index.module.scss';

type Props = PropsWithChildren<{ caseStudy: CaseStudyShape }>;

const Protected = (props: Props) => {
  // store in local storage
  const initialHasAuthed = getFromSessionStorage(props.caseStudy.slug);
  const history = useHistory();
  const [hasAuthed, setHasAuthed] = useState(initialHasAuthed);

  const onSubmit = (values) => {
    const hasAuthed = values.password === props.caseStudy.password;
    setHasAuthed(hasAuthed);

    if (hasAuthed) {
      addToSessionStorage(props.caseStudy.slug, true);
    }
  };

  if (hasAuthed || !props.caseStudy.protected) {
    return <>{props.children}</>;
  }

  const validate = (values) => {
    const errors: any = {};
    if (!values.password || values.password !== props.caseStudy.password) {
      errors.password = 'Invalid password';
    }
    return errors;
  };

  return (
    <div className="fixed inset-0 z-50 bg-black text-white flex items-center justify-center">
      <CursorArea
        theme={Themes.dark}
        className="absolute right-9 md:right-24 lg:right-32 top-5 md:top-10 lg:top-14"
        iconType={IconTypes.close}
      >
        <button className="px-4 py-2" onClick={() => history.push('/')}>
          Close
        </button>
      </CursorArea>
      <div className="px-11 md:px-0">
        <div className={cx('w-full', styles.password)}>
          <Logo isWhite className={styles.logo} />
          <Formik
            validate={validate}
            initialValues={{ password: '' }}
            onSubmit={onSubmit}
          >
            {(props) => (
              <Form>
                <ValidField
                  label="Password"
                  type="text"
                  name="password"
                  placeholder="Enter Password to View"
                  // @ts-ignore
                  autoComplete="off"
                  actionClickHandler={props.handleSubmit}
                  data-lpignore
                />
              </Form>
            )}
          </Formik>
          <a
            href={`mailto:${EMAIL}?subject=Request a password`}
            className={styles.requestPassword}
          >
            Request password
          </a>
        </div>
      </div>
    </div>
  );
};

export default Protected;
