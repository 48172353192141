import React from 'react';
import Wrapper from '../Wrapper';

const Intro = () => {
  return (
    <Wrapper className="max-w-screen-2xl">
      <p className="font-normal text-2xl md:text-3xl lg:text-4xl my-11 lg:mb-16 xl:mb-32">
        Design Director and Creative Consultant{' '}
        <br className="hidden md:block" />
        Delivering Innovative Multi-Disciplinary{' '}
        <br className="hidden md:block" />
        Design Solutions Through Hands on Collaboration.
      </p>
    </Wrapper>
  );
};

export default Intro;
