import React from 'react';
import { FairPremiumPhonesData } from '../../../../types';
import Phone from '../Phone';
import styles from './index.module.scss';

const FairPremiumPhones = ({ data }: FairPremiumPhonesData) => {
  return (
    <div className={styles.root}>
      <div className={styles.grid}>
        {data.map(({ image }, i) => (
          <div className={styles.phone} key={i}>
            <Phone image={image} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FairPremiumPhones;
