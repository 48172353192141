import Image from '../../../Image';
import { CaseStudyShape } from '../../../../types';

import { useMedia } from '../../../../helpers/useMedia';
import Video from '../../../Video';
import RatioContainer from '../../../RatioContainer';

import { useRef } from 'react';

export const CASE_STUDY_HERO = 'case-study-hero';

type HeroProps = {
  hero: CaseStudyShape['hero'];
};

const Hero = ({ hero }: HeroProps) => {
  const isMd = useMedia('(min-width: 768px)');
  const { mobile, desktop } = hero.media;

  const ref = useRef(null);

  const isVideo = !!mobile.videoInline;
  let content;

  const ratio = isMd ? 0.5625 : 1.426;
  if (isVideo) {
    const srcs = isMd
      ? desktop.videoInline || mobile.videoInline
      : mobile.videoInline;
    content = (
      <RatioContainer ratio={ratio}>
        <Video
          autoPlay
          loop
          srcs={srcs}
          poster={mobile.poster}
          className="absolute object-cover inset-0 w-full h-full"
        />
      </RatioContainer>
    );
  } else {
    const src = isMd ? desktop.image || mobile.image : mobile.image;

    content = (
      <Image
        ratio={ratio}
        src={src}
        alt={hero.media.alt}
        className="relative"
        imageClass="absolute object-cover inset-0 w-full h-full"
      />
    );
  }

  return (
    <div className="overflow-hidden relative" ref={ref} id={CASE_STUDY_HERO}>
      <div className="fixed top-0 left-0 w-full">{content}</div>
      <RatioContainer ratio={ratio} />
    </div>
  );
};

export default Hero;
