import { publicUrl } from '../../../../helpers/public';
import cx from 'classnames';
import Image from '../../../Image';
import RatioContainer from '../../../RatioContainer';

import styles from './index.module.scss';

const Phone = ({ image }) => {
  return (
    <div className={styles.root}>
      <RatioContainer ratio={1.849} className={styles.container}>
        <Image
          className={styles.phone}
          imageClass={styles.screenImage}
          ratio={1.849}
          src={publicUrl('/assets/iphone.png')}
          alt="iPhone Screen"
        />
        <div className={styles.screen}>
          <Image
            ratio={2.142}
            src={image}
            imageClass={cx(styles.screenImage, 'w-full')}
          />
        </div>
      </RatioContainer>
    </div>
  );
};

export default Phone;
