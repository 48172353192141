const Play = () => (
  <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
      transform="translate(15.000000, 0.000000)"
    >
      <polygon
        stroke="currentColor"
        strokeWidth="2"
        points="1.62903226 1.72580645 43.0562258 29.4623065 1.62903226 57.9526774"
      ></polygon>
    </g>
  </svg>
);

export default Play;
