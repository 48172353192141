export enum IconTypes {
  'play' = 'play',
  'arrow' = 'arrow',
  'left-arrow' = 'left-arrow',
  'close' = 'close',
}

export enum Themes {
  'dark' = 'dark',
  'light' = 'light',
  'purple' = 'purple',
}
