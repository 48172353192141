import { useInView } from 'react-intersection-observer';
import { Parallax, useController } from 'react-scroll-parallax';
import { ROOT_MARGIN_IN_VIEW } from '../../../../app.constants';
import Image from '../../../Image';
import RatioContainer from '../../../RatioContainer';
import styles from './index.module.scss';

import { ParallaxImageData } from '../../../../types';
import { useMedia } from '../../../../helpers/useMedia';
import { CASE_STUDY_SCROLLER_ID } from '../CaseStudyScroller';

type ParallaxImageProps = {
  data: ParallaxImageData['data'];
};

const py = [-10, 10];

const ParallaxImage = ({ data }: ParallaxImageProps) => {
  const { parallaxController }: { parallaxController: any } = useController();
  const isMd = useMedia('(min-width: 768px)');

  const mobileImage = data.mobileImage || data.image;

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    root: document.getElementById(CASE_STUDY_SCROLLER_ID),
    rootMargin: ROOT_MARGIN_IN_VIEW,
  });

  return (
    <div ref={ref}>
      <RatioContainer
        ratio={isMd ? 0.5611 : 0.703}
        className={styles.container}
      >
        <Parallax y={py} className={styles.imageParallax}>
          <Image
            inView={inView}
            ratio={isMd ? 0.7 : 0.9}
            src={isMd ? data.image : mobileImage}
            alt={data.alt}
            className="relative"
            imageClass="absolute object-cover inset-0 w-full h-full"
            onLoad={() => {
              parallaxController.update();
            }}
          />
        </Parallax>
      </RatioContainer>
    </div>
  );
};

export default ParallaxImage;
