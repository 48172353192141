import { CaseStudyShape } from '../../../../types';

type CaseStudyIntroProps = {
  intro: CaseStudyShape['intro'];
};

const CaseStudyIntro = ({ intro }: CaseStudyIntroProps) => {
  return (
    <section className="px-9 py-9 md:px-24 md:py-24 lg:px-32 xl:px-64">
      <div className="md:flex md:row-auto">
        <div className="mb-4">
          <p>Project</p>
          <p className="copy font-medium">{intro.project}</p>
        </div>
        <div className="mb-4 md:ml-16">
          <p>Creative Role</p>
          <p className="copy font-medium">{intro.role}</p>
        </div>
      </div>
      <div className="mt-20">
        <p
          className="text-xl md:text-2xl lg:text-3xl max-w-4xl"
          dangerouslySetInnerHTML={{ __html: intro.copy }}
        />
      </div>
    </section>
  );
};

export default CaseStudyIntro;
