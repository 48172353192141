import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';
import Video from '../../../Video';
import RatioContainer from '../../../RatioContainer';
import VideoPlayerOverlay from '../VideoPlayerOverlay';

import { ROOT_MARGIN_IN_VIEW } from '../../../../app.constants';
import CursorArea from '../../../Cursor/components/CursorArea';
import { IconTypes, Themes } from '../../../Cursor/types';

import { CASE_STUDY_SCROLLER_ID } from '../CaseStudyScroller';

const VideoWithPlayer = ({ data }) => {
  // this will trigger loading
  const { ref: lazyRef, inView: inViewLoad } = useInView({
    threshold: 0,
    triggerOnce: true,
    root: document.getElementById(CASE_STUDY_SCROLLER_ID),
    rootMargin: ROOT_MARGIN_IN_VIEW,
  });

  // this starts/stops the video
  const { ref, inView } = useInView({
    threshold: 0,
    root: document.getElementById(CASE_STUDY_SCROLLER_ID),
    rootMargin: '100px 0px 100px 0px',
  });

  const ratio = data.ratio || 0.5611;

  const videoRef: any = useRef(null);

  const [showPlayer, setShowPlayer] = useState(false);

  useEffect(() => {
    const video: HTMLVideoElement = videoRef.current;
    if (!video) return null;

    if (inView || !showPlayer) {
      video.play();
    } else if (!inView || showPlayer) {
      video.pause();
    }
  }, [inView, showPlayer, videoRef]);

  const hasOverlayVideo = !!data.video;

  const showVideoPlayer = () => {
    if (!hasOverlayVideo) return;

    setShowPlayer(true);
    const el = document.getElementById(CASE_STUDY_SCROLLER_ID);
    el.style.overflow = 'hidden';
  };

  const hideVideoPlayer = () => {
    setShowPlayer(false);
    const el = document.getElementById(CASE_STUDY_SCROLLER_ID);
    el.style.overflow = '';
  };

  const objectFit = data.objectFit || 'cover';
  return (
    <div ref={lazyRef}>
      <div ref={ref}>
        {showPlayer && (
          <VideoPlayerOverlay
            theme={Themes.light}
            // theme={data.theme}
            onClose={hideVideoPlayer}
            srcs={data.video}
            poster={data.poster}
          />
        )}

        <CursorArea
          iconType={IconTypes.play}
          theme={data.theme}
          disabled={!hasOverlayVideo}
        >
          <div onClick={showVideoPlayer} className={data.className}>
            <RatioContainer ratio={ratio} className="overflow-hidden">
              {inViewLoad && (
                <Video
                  className={cx(
                    'absolute inset-0 w-full h-full',
                    `object-${objectFit}`,
                  )}
                  srcs={data.videoInline}
                  poster={data.poster}
                  autoPlay
                  loop
                  muted
                  ref={videoRef}
                />
              )}
            </RatioContainer>
          </div>
        </CursorArea>
      </div>
    </div>
  );
};

export default VideoWithPlayer;
