import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Parallax, useController } from 'react-scroll-parallax';
import CursorArea from '../Cursor/components/CursorArea';
import { IconTypes, Themes } from '../Cursor/types';
import Image from '../Image';
import styles from './index.module.scss';

const py = [-15, 15];

const CaseStudyCard = ({
  caption,
  image,
  className,
  slug,
  theme,
}: {
  caption: string;
  image: string;
  className?: string;
  slug: string;
  theme: keyof typeof Themes;
}) => {
  const { parallaxController }: any = useController();

  return (
    <div
      className={cx(
        'block w-full max-w-md mb-11 lg:-mt-64 lg:w-1/2 lg:max-w-full lg:first:mt-1',
        className,
      )}
    >
      <Link to={`/case-study/${slug}`}>
        <CursorArea iconType={IconTypes.arrow} theme={theme}>
          <figure>
            <div className={styles.container}>
              <Parallax y={py} className={styles.imageParallax}>
                <Image
                  ratio={1.867}
                  src={image}
                  alt={caption}
                  onLoad={() => parallaxController.update()}
                />
              </Parallax>
            </div>

            <figcaption className="mt-6 flex row-auto justify-between">
              <p className="copy font-medium">{caption}</p>
              <span className={styles.arrow}>
                <svg viewBox="0 0 49 39" version="1.1">
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="currentColor"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <g transform="translate(2.000000, 2.000000)">
                      <line
                        x1="0.5"
                        y1="17.5"
                        x2="44.5"
                        y2="17.5"
                        id="Line"
                      ></line>
                      <line
                        x1="27.5"
                        y1="0.5"
                        x2="44.5"
                        y2="17.5"
                        id="Line-Copy"
                      ></line>
                      <line
                        x1="44.5"
                        y1="17.5"
                        x2="27.5"
                        y2="34.5"
                        id="Line-Copy-2"
                      ></line>
                    </g>
                  </g>
                </svg>
              </span>
            </figcaption>
          </figure>
        </CursorArea>
      </Link>
    </div>
  );
};

export default CaseStudyCard;
