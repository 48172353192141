import { useEffect, useRef, useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { useHistory } from 'react-router';
import BlurReveal from '../BlurReveal';
import { useScroller } from '../../../../helpers/useScroller';

export const CASE_STUDY_SCROLLER_ID = 'case-study-scroller';

const CaseStudyScroller = (props) => {
  // observe scrolling
  const ref = useRef(null);
  const history = useHistory();
  const onScrollComplete = () => history.push('/');
  useScroller({ ref, tolerance: 10, onScrollComplete });

  //wait for the ref to be ready with an element and force a re render for the provider
  const [scrollContainer, setScrollContainer] = useState(null);
  useEffect(() => {
    setScrollContainer(ref.current);
  }, [ref]);

  return (
    <div
      id={CASE_STUDY_SCROLLER_ID}
      className="fixed inset-0 overflow-y-auto z-10"
      ref={ref}
    >
      {scrollContainer && (
        <ParallaxProvider scrollContainer={scrollContainer}>
          {props.children}
          <BlurReveal scrollContainer={scrollContainer} />
        </ParallaxProvider>
      )}
    </div>
  );
};

export default CaseStudyScroller;
